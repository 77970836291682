import { CellContext, ColumnDef } from "@tanstack/react-table";
import { BaseReport } from "@winclap-platform/models/schemas/reports";
import { ChevronRight, RefreshCcw, Trash2 } from "lucide-react";
import { getAppType } from "~/utils/apps";
import { countryMap } from "~/utils/countries";
import { Button, buttonVariants } from "@winclap-platform/ui/components/button";
import { useRouter } from "next/router";
import { Protect, useOrganization } from "@clerk/nextjs";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@winclap-platform/ui/components/dialog";
import { useState } from "react";
import {
  useAppOptions,
  useDeleteReport,
  useRegenerateReport,
} from "~/hooks/reports/api";
import { formatInUTC } from "~/utils/dates";
import { ActionsHeader, SortableHeader, StyledHeader } from "./table-header";

const ReportActionButton = ({ row }: CellContext<BaseReport, unknown>) => {
  const router = useRouter();
  const { organization: activeOrg } = useOrganization();
  return (
    row.original.report_status === "Completed" && (
      <Button
        variant="table"
        size="icon"
        onClick={() => {
          if (row.original.report_status === "Completed") {
            void router.push(
              `/reporting/${activeOrg?.slug}/${row.original.report_id}`,
            );
          }
        }}
      >
        <ChevronRight className="size-5" />
      </Button>
    )
  );
};

const ReportResetButton = ({ row }: CellContext<BaseReport, unknown>) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { mutate, isPending } = useRegenerateReport();
  const regenerateReport = () => {
    mutate(
      { reportId: row.original.report_id },
      {
        onSettled: () => setOpenDialog(false),
      },
    );
  };
  return (
    row.original.report_status === "Completed" && (
      <Protect role="org:admin">
        <Button variant="table" size="icon" onClick={() => setOpenDialog(true)}>
          <RefreshCcw className="size-5" />
        </Button>
        <Dialog open={openDialog} onOpenChange={setOpenDialog}>
          <DialogContent closable={false} className="p-8">
            <DialogTitle>Do you want to regenerate the report?</DialogTitle>
            <DialogDescription>
              Regenerating the report will overwrite all saved changes. This action
              cannot be undone.
            </DialogDescription>
            <div className="flex justify-end gap-2">
              <DialogClose className={buttonVariants({ variant: "outline" })}>
                Don&apos;t regenerate
              </DialogClose>
              <Button onClick={regenerateReport} loading={isPending}>
                Regenerate
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </Protect>
    )
  );
};

const ReportDeleteButton = ({ row }: CellContext<BaseReport, unknown>) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { mutate, isPending } = useDeleteReport();
  const deleteReport = () => {
    mutate(row.original.report_id, {
      onSettled: () => setOpenDialog(false),
    });
  };
  return (
    <Protect role="org:admin">
      <Button variant="table" size="icon" onClick={() => setOpenDialog(true)}>
        <Trash2 className="size-5" />
      </Button>
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent closable={false} className="p-8">
          <DialogTitle>Are you sure you want to delete this report?</DialogTitle>
          <DialogDescription>This action cannot be undone.</DialogDescription>
          <div className="flex justify-end gap-2">
            <DialogClose className={buttonVariants({ variant: "outline" })}>
              Cancel
            </DialogClose>
            <Button onClick={deleteReport} loading={isPending} variant="destructive">
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Protect>
  );
};

export const AppNameCell = <
  TDataset extends { report_type: string; app_id: string },
>({
  row,
}: CellContext<TDataset, unknown>) => {
  const { data: options } = useAppOptions(row.original.report_type);
  const app = options.mobileOptions?.find(
    (app) => app.value === row.original.app_id,
  );
  return app?.label || row.original.app_id || "";
};

export const reportsColumns: ColumnDef<BaseReport>[] = [
  {
    accessorKey: "week_date",
    header: (context) => <SortableHeader context={context} label="Week / Year" />,
    cell: ({ row }) => formatInUTC(row.original.week_date, "'W'ww / yyyy"),
    size: 130,
  },
  {
    accessorKey: "report_name",
    cell: ({ row }) => row.original.report_name,
    header: () => <StyledHeader label="Report name" />,
    size: 160,
  },
  {
    accessorKey: "app_id",
    cell: AppNameCell,
    header: (context) => <SortableHeader context={context} label={"App ID"} />,
    size: 160,
  },
  {
    accessorKey: "business_unit_name",
    header: (context) => <SortableHeader context={context} label="Business Unit" />,
    cell: ({ row }) => row.original.business_unit_name,
    size: 160,
  },
  {
    accessorKey: "app_os",
    accessorFn: (row) => getAppType(row.app_id),
    cell: ({ row }) =>
      row.original.report_type === "wow_report"
        ? getAppType(row.original.app_id)
        : "Web",
    header: () => <StyledHeader label="OS" />,
    size: 110,
  },
  {
    accessorKey: "country",
    header: () => <StyledHeader label="Countries" />,
    cell: ({ row }) =>
      (row.original.report_type === "wow_report" ||
        row.original.report_type === "wow_web") &&
      row.original.countries
        .map((country) => countryMap[country.country_code])
        .join(", "),
    size: 300,
  },
  {
    accessorKey: "report_status",
    header: () => <StyledHeader label="Status" />,
    cell: ({ row }) => {
      const reportStatus = row.original.report_status;
      return (
        <span
          className={
            reportStatus === "Completed"
              ? "text-success"
              : reportStatus === "Failed"
                ? "text-error"
                : ""
          }
        >
          {reportStatus}
        </span>
      );
    },
    size: 100,
  },
  {
    accessorKey: "refresh",
    header: ActionsHeader,
    cell: ReportResetButton,
    size: 44,
  },
  {
    accessorKey: "delete",
    header: ActionsHeader,
    cell: ReportDeleteButton,
    size: 44,
  },
  {
    accessorKey: "action",
    header: ActionsHeader,
    size: 48,
    cell: ReportActionButton,
  },
];
